import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import '../css/home.css';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import LogoAnimation from './LogoAnimation';

export default function Header() {
    const [isActive, setIsActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setCursorPosition({ x: e.clientX, y: e.clientY });
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const handleMenuItemHover = (index) => {
        setHoveredIndex(index);
    };
    const toggleMenu = () => {
        setIsActive(!isActive);
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsActive(false);
        setIsOpen(false);
    };

    return (
        <>
            <header>
                <Navbar fixed="top">
                    <Container fluid>
                        <Navbar.Brand href="/" className="Logo">
                            <LogoAnimation />
                        </Navbar.Brand>
                        <Link to='/ContactUs'><button className="ms-auto text-2xl contact-button">Contact us</button></Link>
                        <nav className="nav">
                            <button className={`toggle-menu ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
                                <span></span>
                            </button>
                        </nav>
                        <div id="menu" className={isOpen ? 'open' : ''}>
                            <div
                                className={`cursor ${hoveredIndex !== null ? 'big' : ''}`}
                                style={{ left: cursorPosition.x, top: cursorPosition.y }}
                            ></div>
                            <div
                                className={`cursor cursor-outline ${hoveredIndex !== null ? 'big' : ''}`}
                                style={{ left: cursorPosition.x, top: cursorPosition.y }}
                            ></div>
                            <Container fluid>
                                <Row>
                                    <Col lg={6} md={6}>
                                        <nav className="main-nav">
                                            <Link to="/" onClick={closeMenu} onMouseEnter={() => handleMenuItemHover(0)}
                                                onMouseLeave={() => handleMenuItemHover(null)}>Home</Link>  
                                            <Link to="/about" onClick={closeMenu} onMouseEnter={() => handleMenuItemHover(0)}
                                                onMouseLeave={() => handleMenuItemHover(null)}>About Us</Link>
                                                   <Link to="/Quality-and-Process" onClick={closeMenu} onMouseEnter={() => handleMenuItemHover(0)}
                                                onMouseLeave={() => handleMenuItemHover(null)}>Quality & Process</Link>
                                            <Link to="/Crystal-Sand" onClick={closeMenu} onMouseEnter={() => handleMenuItemHover(0)}
                                                onMouseLeave={() => handleMenuItemHover(null)}>Crystal Sand</Link>
                                            <Link to="/Ready-Mix-Concrete" onClick={closeMenu} onMouseEnter={() => handleMenuItemHover(0)}
                                                onMouseLeave={() => handleMenuItemHover(null)}>RMC</Link>
                                            <Link to="/Aggregates" onClick={closeMenu} onMouseEnter={() => handleMenuItemHover(0)}
                                                onMouseLeave={() => handleMenuItemHover(null)}>Aggregates</Link>
                                        </nav>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <nav className="main-nav2">
                                            {/* <Link to="/Clients" onClick={closeMenu}>Clients</Link> */}
                                            <Link to="/ContactUs" onClick={closeMenu}>Contact us</Link>
                                        </nav>
                                    </Col>
                                </Row>
                            </Container>
                            <footer className="menu-footer">
                                <nav className="footer-nav">
                                    <ul>
                                        <li>
                                            <Link to="#" onClick={closeMenu}>
                                                <FontAwesomeIcon icon={faInstagram} /> Instagram
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={closeMenu}>
                                                <FontAwesomeIcon icon={faFacebook} /> Facebook
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={closeMenu}>
                                                <FontAwesomeIcon icon={faYoutube} /> YouTube
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={closeMenu}>
                                                <FontAwesomeIcon icon={faXTwitter} /> Twitter
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </footer>
                        </div>
                    </Container>
                </Navbar>
            </header>
        </>
    );
}
