import React, { useState, useEffect } from 'react'
import '../css/home.css'
import backgroundVideo from '../assets/Videos/blue & peach 00.1.mp4'
import Logo from '../assets/Images/ENDULA LOGO-01.png'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faXTwitter,
  faYoutube,
  faFacebook,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'
export default function Footer () {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleChange = e => {
    setEmail(e.target.value)
    setError('') // Clear error message when typing
  }

  const validateEmail = email => {
    // Basic email regex pattern for validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(email)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.')
      return
    }
    // Proceed with form submission if email is valid
    console.log('Form submitted with email:', email)
    setEmail('')
  }

  const [hasScrolled, setHasScrolled] = useState(false)
  const handleScroll = () => {
    setHasScrolled(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <div className='z-50'>
      <section class='section-wrap footer forSameFonts'>
        <div class='section footer'>
          <div class='video-wrap footer' id='footerVdo'>
            <div
              data-poster-url='videos/new-poster-00001.jpg'
              data-video-urls='videos/new-transcode.mp4,videos/new-transcode.webm'
              data-autoplay='true'
              data-loop='true'
              data-wf-ignore='true'
              class='gradient-video footer w-background-video w-background-video-atom'
            >
              <video autoPlay loop muted playsInline className='-z-50'>
                <source src={backgroundVideo} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </div>
            <div class='background-noise-new flex justify-center w-full z-50'>
              <div className='w-[95%] sm:w-[80%] md:w-[85%] md:mt-3 lg:mt-2 xl:mt-5'>
                <div className='fotert-top  flex justify-between 2xl:mt-5 '>
                  <div>
                    <img
                      src={Logo}
                      className='sm:mt-10 w-36 hidden sm:block'
                      alt='logo'
                    />
                  </div>
                  <div className='xl:gap-10  gap-3 lg:mr-[-30px] xl:mr-[2.5rem] 2xl:mr-[11.5rem]  lg:block md:mt-10 lg:mt-[5.25rem] hidden'>
                    <Link
                      className='text-white z-50 text-md xl:text-2xl lg:text-xl pr-8 text-decoration-none sm:pt-8'
                      to='/Crystal-Sand'
                      style={{
                        pointerEvents: 'auto'
                      }}
                    >
                      / <span className='forSameFonts'>Crystal Sand</span>
                    </Link>
                    <Link
                      className='text-white text-md lg:text-xl xl:text-2xl pr-8 text-decoration-none sm:pt-8'
                      to='/Aggregates'
                      style={{
                        pointerEvents: 'auto'
                      }}
                    >
                      / <span className='forSameFonts'>Aggregates</span>
                    </Link>
                    <Link
                      className='text-white text-md lg:text-xl xl:text-2xl pr-8 text-decoration-none sm:pt-8'
                      to='/Ready-Mix-Concrete'
                      style={{
                        pointerEvents: 'auto'
                      }}
                    >
                      / &nbsp;
                      <span className='forSameFonts'>RMC</span>
                    </Link>
                  </div>
                </div>

                <div className='footer-middle forSameFonts  flex sm:justify-between  flex-col-reverse md:flex-row mt-32 sm:mt-[3rem] lg:mt-[4rem]'>
                  <div className='md:w-[400px] lg:w-[50%] sm:w-[300px]  '>
                    <div className='Footer-mail-desc lg:text-4xl xl:text-5xl mt-20 sm:mt-3 leading-4 text-2xl forSameFonts'>
                      Keep up with the news
                    </div>
                    <Form className='email-form' onSubmit={handleSubmit}>
                      <div className='input-container relative z-50'>
                        <input
                          required
                          type='email'
                          placeholder='Email Address'
                          className='email-input w-[90%] z-[999] lg:w-[500px] md:w-[300px]  text-white text-4xl placeholder-white bg-transparent border-b-2'
                          value={email}
                          style={{
                            pointerEvents: 'auto',
                            fontSize: '18px'
                          }}
                          onChange={handleChange}
                        />
                        <button
                          className='top-0 absolute right-3 md:right-[2rem] lg:right-3 xl:right-[6rem] 2xl:right-[18rem]'
                          onClick={handleSubmit}
                          type='submit'
                          style={{
                            pointerEvents: 'auto',
                            zIndex: '999'
                          }}
                        >
                          <img
                            src='https://www.egn.university/images/slider-arrow.svg'
                            alt='arrow icon'
                          />
                        </button>
                      </div>
                      {error && <div className='error-message'>{error}</div>}
                    </Form>
                  </div>
                  <div className='footer-center-right md:w-[50%] lg:w-[42%] 2xl:w-[40%] w-[60%] sm:w-[40%] mt-10 md:mt-0 z-50  flex justify-between items-start'>
                    <div className='footer-center-wrap flex gap-4 flex-col mr-6 sm:mr-0 xl:flex-row lg:justify-between '>
                      <div className='colomn-1 flex flex-col  sm:gap-0 items-start forSameFonts'>
                        <a
                          className='text-white text-xl  AnimationTitle sm:pb-0  no-underline'
                          href='https://'
                        >
                          Privacy
                        </a>
                        <a
                          className='text-white text-xl py-2 sm:py-0 sm:pb-0 AnimationTitle  no-underline'
                          href='https://'
                        >
                          Cookie Policy
                        </a>
                        <a
                          className='text-white text-xl  sm:pb-0 AnimationTitle  no-underline'
                          href='https://'
                        >
                          Terms Of Use
                        </a>
                      </div>
                      <div className='colomn-2 flex  sm:gap-0  sm:mt-0 flex-col items-start'>
                     <Link to='tel:9492026050'  style={{
                        pointerEvents: 'auto',
                        zIndex: '999'
                      }}
                      className='text-decoration-none text-[white]'
                      >
                     9492026050
                     </Link>
                     <Link to='mailto:Info@endulainfra.in' className='text-white text-xl pb-1 sm:pb-0 py-2 no-underline'
                         style={{
                          pointerEvents: 'auto',
                          zIndex: '999'
                        }}>
                        Info@endulainfra.in
                        </Link>
                      </div>
                    </div>
                    <div className='flex flex-row gap-4 ml-2  ' id='ExtraGap'>
                      <div className='text-white xs:text-lg xxl:text-2xl md:text-3xl text-3xl lg:text-2xl xl:text-3xl'>
                        <FontAwesomeIcon icon={faYoutube} />
                      </div>
                      <div className='text-white xs:text-lg xxl:text-2xl text-3xl md:text-3xl lg:text-2xl xl:text-3xl'>
                        <FontAwesomeIcon icon={faFacebook} />
                      </div>
                      <div className='text-white text-3xl xs:text-lg xxl:text-2xl md:text-3xl lg:text-2xl xl:text-3xl'>
                        <FontAwesomeIcon icon={faInstagram} />
                      </div>
                      <div className='text-white text-3xl xs:text-lg xxl:text-2xl md:text-3xl lg:text-2xl xl:text-3xl'>
                        <FontAwesomeIcon icon={faXTwitter} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='footer-bottom xs:mt-10 mt-28 sm:mt-8 2xl:mt-32 flex justify-between items-start'>
                  <div className='md:w-[50%] lg:w-[68%] xl:w-[58%] 2xl:w-[60%]'>
                    <p className='text-white tetx-2xl forSameFonts'>
                      &#9400; 2025 to Endula. All rights reserved.
                    </p>
                  </div>
                  <div className='md:w-[50%] lg:w-[50%] xl:w-[42%] 2xl:w-[40%]   '>
                    <Link
                      to='https://novatales.com/'
                      target='_blank'
                      className='text-decoration-none text-[white]'
                      style={{
                        pointerEvents: 'auto',
                        zIndex: '999'
                      }}
                    >
                      <p className='text-white text-2xl forSameFonts'>
                        Powered By @ Novatales Media
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
