import React, { useState, useEffect } from 'react'
import '../css/home.css'
import backgroundVideo from '../assets/Videos/blue & peach 00.1.mp4'
import backgroundVideo2 from '../assets/Videos/homevideo-2.mp4'
import LetterDrop from './LetterDrop'
import { Container, Row, Col } from 'react-bootstrap'
import SimpleCarousel from './SimpleCarousel'
import ButtonCardDisplay from './ButtonCardDisplay'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Lottie from 'lottie-react'
import mixingConcrete from '../Lottiefiles/rmc truck.json'
import crystalSand from "../Lottiefiles/new-crystal-Sand.json"
import Aggregates from '../Lottiefiles/json.json'
import Spiral from '../assets/Images/spiral.png'
import Arrow from '../assets/Images/arrow.png'
import Clients from './Clients'
export default function HomePage () {
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })
  const [isCarouselHovered, setIsCarouselHovered] = useState(false)
  useEffect(() => {
    const handleMouseMove = e => {
      setCursorPosition({ x: e.clientX, y: e.clientY })
    }

    document.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const handleMenuItemHover = index => {
    setHoveredIndex(index)
  }
  const testimonials = [
    {
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Yeasin Arafat",
      title: "Designer, LLCG Team",
      videoSrc: "https://i.ibb.co/DWhSr6S/play-button2.png"
    },
    {
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Yeasin Arafat",
      title: "Designer, LLCG Team",
      videoSrc: "https://i.ibb.co/DWhSr6S/play-button2.png"
    }, {
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Yeasin Arafat",
      title: "Designer, LLCG Team",
      videoSrc: "https://i.ibb.co/DWhSr6S/play-button2.png"
    }, {
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      name: "Yeasin Arafat",
      title: "Designer, LLCG Team",
      videoSrc: "https://i.ibb.co/DWhSr6S/play-button2.png"
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <div className='HomePage'>
      {!isCarouselHovered && (
        <>
          <div
            className={`cursor ${hoveredIndex !== null ? 'big' : ''}`}
            style={{ left: cursorPosition.x, top: cursorPosition.y }}
          ></div>
          <div
            className={`cursor cursor-outline ${
              hoveredIndex !== null ? 'big' : ''
            }`}
            style={{ left: cursorPosition.x, top: cursorPosition.y }}
          ></div>
        </>
      )}
      <div className='double-sections'>
        <div className='heading-wrap'>
          <div
            className='content'
            onMouseEnter={() => handleMenuItemHover(0)}
            onMouseLeave={() => handleMenuItemHover(null)}
          >
            <LetterDrop text1='The Future of' text2='Sand is here' />
          </div>
        </div>
        <section className='section hero'>
          <div className='background-noise-new'></div>
          <div className='video-wrap'>
            <div className='gradient-video w-background-video w-background-video-atom'>
              <video id='video-1' autoPlay loop muted playsInline>
                <source src={backgroundVideo} />
                <source src={backgroundVideo} />
              </video>
            </div>
          </div>
        </section>
        <div className='section-wrap video2'>
          <div className='section video'>
            <div className='background-noise-new'></div>
            <div className='video-univ-wrap'>
              <div className='video-univ w-background-video w-background-video-atom'>
                <video id='video-2' autoPlay loop muted playsInline>
                  <source src={backgroundVideo2} />
                  <source src={backgroundVideo2} />
                </video>
              </div>
            </div>
            <div className='section-anim-trigger'></div>
          </div>
        </div>
      </div>
      <section className='section-spacer video'></section>
      <div className='section-wrap studying'>
        <div className='section studying'>
          <Container fluid>
            <div className='section-cont studying'>
              <h2
                onMouseEnter={() => handleMenuItemHover(0)}
                onMouseLeave={() => handleMenuItemHover(null)}
                className='h3-heading studing'
              >
                Why Choose Us
              </h2>
              <div className='section-descr studying forSameFonts'>
                Endula Infra has a vast experience of decades in the
                infrastructural industry when it comes to supplying superior
                quality aggregates as well as the RMC. Endula Infra specializes
                in aggregate and RMC services that are well-coordinated and as
                per the fine details of the clients’ needs no matter how big or
                small they are.
              </div>
            </div>
            <div className='studying-points-grid'>
              <div className='studying-point '>
                <div className='icon-cont icon-1  '>
                  <Lottie animationData={mixingConcrete}  className='lg:mt-[-60px] md:mt-[-78px]' loop={true} />
                </div>
                <div
                  className='studying-point-name mt-5 AnimationTitle'
                  onMouseEnter={() => handleMenuItemHover(0)}
                  onMouseLeave={() => handleMenuItemHover(null)}
                >
                  Ready Mix Concrete
                </div>
                <div className='studying-point-text forSameFonts'>
                  RMC is a concrete manufactured in a batching plant for supply
                  to sites in freshly mixed or unhardened state, to be mixed
                  with water and compacted to form concrete. It is used
                  extensively in construction industry since it has many
                  advantages over on-site method of concrete production.
                </div>
                <div className='arrow-black-hor-left'>
                  <img
                    src={Arrow}
                    alt='Arrow Left'
                    className='arrow-black-end-left'
                  />
                </div>
                <div className='arrow-black-vert-up'>
                  <img
                    src={Arrow}
                    alt='Arrow Up'
                    className='arrow-black-end-up'
                  />
                </div>
              </div>
              <div className='studying-point right '>
                <div className='icon-cont icon-2'>
                  <Lottie animationData={crystalSand} className='mt-[5.5rem] sm:mt-7 md:mt-0' loop={true} />
                </div>
                <div
                  className='studying-point-name mt-5 AnimationTitle'
                  onMouseEnter={() => handleMenuItemHover(0)}
                  onMouseLeave={() => handleMenuItemHover(null)}
                >
                  Crystal Sand
                </div>
                <div className='studying-point-text forSameFonts'>
                  Until recently River sand was the only kind of sand for
                  construction activities, since it was very economical and
                  abundantly available. But these days its very difficult to get
                  good quality sand at short distances from developing cities,
                  due to over-utilization of resources in the recent years.
                </div>
                <div className='arrow-black-hor-right'>
                  <img
                    src={Arrow}
                    alt='Arrow Right'
                    className='arrow-black-end-right'
                  />
                </div>
              </div>
              <div className='studying-point bottom '>
                <div className='icon-cont icon-3 '>
                  <Lottie animationData={Aggregates} className='lg:mt-[-20px] md:mt-[-30px] md:mb-3 md:pb-5' loop={true} />
                </div>
                <div
                  className='studying-point-name  md:mt-8 mt-5 AnimationTitle' id='specialIdIcon'
                  onMouseEnter={() => handleMenuItemHover(0)}
                  onMouseLeave={() => handleMenuItemHover(null)}
                >
                  Aggregates
                </div>
                <div className='studying-point-text forSameFonts'>
                  Aggregates are the materials used in construction and civil
                  engineering. They include sand, gravel, crushed stone, and
                  recycled concrete.
                </div>
                <div className='arrow-black-vert-down'>
                  <img
                    src={Arrow}
                    alt='Arrow Down'
                    className='arrow-black-end-down'
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className='section-anim-trigger'></div>
      </div>
      <section className='section-spacer studying'></section>
      <section className='section-wrap faculties'>
        <div className='section faculties'>
          <div className='section-cont faculty'>
            <h2 className='h2-heading facullty md:mt-[-30px] md:text-[6rem]' >About</h2>
            <div className='section-descr faculty forSameFonts'>
              Endula Infra is a Stone crushing industry that is based in
              Hyderabad and has been in existence for more than three decades
              placing the company as an industry legend in stone crushing.
            </div>
          </div>
          <Row
            className='carousel-container'
            onMouseEnter={() => setIsCarouselHovered(true)}
            onMouseLeave={() => setIsCarouselHovered(false)}
          >
            <SimpleCarousel />
          </Row>
          <ButtonCardDisplay />
          <div className='design'>
            <img src={Spiral} alt='' />
          </div>
          <div className='section-anim-trigger news'></div>
        </div>
      </section>
      <section className='section-spacer faculty'></section>
      <Clients />
      <section className='section-wrap news'>
        <div className='section news'>
          <div className='section-cont news'>
            <Container fluid className='testimonial-area forSameFonts' >
              <div >
                <h2
                  onMouseEnter={() => handleMenuItemHover(0)}
                  onMouseLeave={() => handleMenuItemHover(null)}
                  className=' news text-[3.5rem] md:text-[4.5rem] lg:text-[5.5rem] lg:mt-[-72px] xl:mb-10 xl:text-[7.5rem] text-center' id='TestiMonial'
                >
                  Testimonials
                </h2>
              </div>
              <Container className='testimonial-content' id="Gutter">
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index}>
                      <Row className='justify-content-center'>
                        <Col className='px-2 mt-5 mb-5'>
                          <div className='single-testimonial'>
                            <div className='round-1 round'></div>
                            <div className='round-2 round'></div>
                            <p className='xl:text-xl 2xl:text-3xl md:text-md py-11 px-5 forSameFonts' id='TestParagraph'>{testimonial.text}</p>
                            <div className='client-info'>
                              <div className='client-details'>
                                <h6>{testimonial.name}</h6>
                                <span>{testimonial.title}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Slider>
              </Container>
            </Container>
            <div className="section-anim-trigger footer"></div>
          </div>
        </div>
      </section>
      <section className="section-spacer news"></section>
    </div>
  )
}
