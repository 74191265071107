import React, { useEffect, useRef } from 'react';
import '../css/home.css';

const LetterDrop = ({ text1, text2 }) => {
  const containerRef1 = useRef(null);
  const containerRef2 = useRef(null);

  useEffect(() => {
    const animateText = (text, containerRef) => {
      const letters = text.split('');
      containerRef.current.innerHTML = '';
      letters.forEach((letter) => {
        const span = document.createElement('span');
        span.className = 'letterDrop';
        span.style.animationDelay = `${Math.random() * 0.8 + 0.1}s`; // Random delay between 0.1s and 0.9s
        span.innerHTML = letter === ' ' ? '&nbsp;' : letter; // Handle spaces
        containerRef.current.appendChild(span);
      });
    };

    animateText(text1, containerRef1);
    animateText(text2, containerRef2);
  }, [text1, text2]);

  return (
    <div>
      <h1 ref={containerRef1} className="letterDropContainer text-[18px] lg:text-[65px] xl:text-8xl 3xl:text-10xl 2xl:text-9xl  2xl:leading-[120px] leading-[65px] md:leading-[80px]" aria-hidden="true"></h1>
      <h1 ref={containerRef2} className="letterDropContainer text-xl mt-lg-5 lg:text-[65px] xl:text-8xl 3xl:text-10xl 2xl:text-9xl  2xl:leading-[120px] leading-[65px] md:leading-[80px]" aria-hidden="true"></h1>
    </div>
  );
};

export default LetterDrop;
