import React, { useEffect } from 'react'
import CrystalSandBanner from '../assets/Videos/CRYSTAL SAND (5.74 MB).mp4'
import '../css/crystal.css'
import '../css/MediaQueries/MediaQueryCrystalSand.css'
import { Container, Row } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import SandImage from '../assets/Images/CRYSTAL SAND.jpg'
import CrystalDifference from './CrystalDifference'
export default function CrystalSandPage () {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='crystal-sand'>
      <Helmet>
        <title>
          Best aggregates and Crystal sand Manufacturer | Endula Infra
        </title>
        <meta
          name='keywords'
          content='construction sand,p sand manufacturers,aggregates and sand,best sand for construction,construction materials sand,sand for building construction.'
        />
        <meta
          name='description'
          content='Endula Infra is your go-to manufacturer for superior aggregates and crystal sand, offering the finest materials to bring purity and excellence to every build.'
        />
      </Helmet>
      <div className='fac-hero'>
        <div className='heading-wrap fac'>
          <h1
            className='hero-heading'
            style={{ opacity: 1 }}
            words-slide-up=''
            text-split=''
          >
            Crystal Sand
          </h1>
        </div>
        <div
          className='arrows-trigger fac'
          style={{
            transform:
              'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'
          }}
        ></div>
        <div className='background-noise-new'></div>
        <div className='hero-gradient'></div>
        <div className='video-fac w-background-video w-background-video-atom flex justify-center items-center'>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          >
            <source src={CrystalSandBanner} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='section-anim-trigger'></div>
      </div>
      <section className='section-spacer fac-video'></section>
      <div className='section-wrap email'>
        <section className='section email about-section'>
          <div className='section-cont email d-flex justify-content-center align-items-center'>
            <h2 className='h3-heading research'>The Future of Sand is here</h2>
            <div className='email-text-wrap'>
              <div
                style={{
                  transform:
                    'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                  opacity: 1
                }}
                className='email-heading AnimationTitle '
              >
                Until recently River sand was the only kind of sand for
                construction activities, since it was very economical and
                abundantly available. But these days its very difficult to get
                good quality sand at short distances from developing cities ,
                due to over utilization of resources in the recent years.
              </div>
              <div
                data-w-id='cb010bdd-2817-14a8-ded0-2e219e70f18c'
                style={{
                  transform:
                    'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                  opacity: 1
                }}
                className='email-sm-text-wrap'
              >
                <div className='text-20px email forSameFonts'>
                  The phenomenal growth in construction industry over the last
                  decade has contributed to a wide gap between the supply and
                  demand of natural sand. In the process a lot of damage also
                  has been done to eco-systems by dredging sand from river beds
                  leading to the depletion of water ground levels in the various
                  parts of the country. This situation created a great need for
                  manufactured sand as a replacement for river sand.
                </div>
                <div className='text-20px email last forSameFonts'>
                  Across the world, there is growing support for the use of
                  manufactured sand, especially in the production of concrete.
                  Structural Engineers and construction technologists highly
                  recommend manufactured sand for its perfect particle shape,
                  consistent gradation, and zero impurities. This sand also
                  eliminates other constraints of river sand, like availability
                  and price fluctuation. This is the future of sand as we know
                  it. Welcome to the Future.
                </div>
              </div>
            </div>
          </div>
          <div className='section-anim-trigger'></div>
        </section>
      </div>
      <div className='section-spacer email'></div>
      <div className='section-wrap programs'>
        <section className='section program'>
          <div className='section-cont program'>
            <div className='benefits-heading'>
              <h2>Benefits of Crystal Sand</h2>
            </div>
            <CrystalDifference />
            <div className='parallax-anim-trigger'></div>
          </div>
        </section>
      </div>
      <section className='section-spacer programs'></section>
      <div className='section-wrap people'>
        <section className='section people'>
          <div className='section-cont people'>
            <div
              className='people-heading people heading-font '
              style={{
                transform:
                  'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                opacity: 1
              }}
            >
              Why Crystal Sand?
            </div>
            <div
              className='people-section-descr science people forSameFonts'
              style={{
                transform:
                  'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                opacity: 1
              }}
            >
              Crystal sand confirms to standards as per ‘vaastu-shastra’, due to
              absence of impurities like bones, river shells and other organic
              material. This will bring happiness, prosperity and peace to the
              owners and persons living in buildings constructed with ‘Crystal
              Sand’. As the surface area is clean so, the consumption of cement
              is reduced. No additives required.
            </div>
          </div>
        </section>
      </div>
      <section className='section-spacer people'></section>
    </div>
  )
}
