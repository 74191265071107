import React, { useState } from 'react'
import '../css/crystal.css'

export default function CrystalDifference () {
  const data = [
    {
      left: {
        description:
          'Gives better compressive strength as compared to river sand.',
        background: 'rgb(16 126 161)',
        number: '01'
      },
      right: {
        description:
          'Gives low compressive strength as compared to crystal sand. '
      }
    },
    {
      left: {
        description:
          'As the voids are filled properly, strength of the concrete is achieved.',
        background: 'rgb(16 126 161)',
        number: '02'
      },
      right: {
        description:
          'As the voids are not filled properly, strength of the concrete is not achieved.'
      }
    },
    {
      left: {
        description:
          'Can be produced in ample quantity, so it is cheaper as compared to natural sand.',
        background: 'rgb(16 126 161)',
        number: '03'
      },
      right: {
        description: 'Available in less quantity so it is costlier.'
      }
    },
    {
      left: {
        description:
          'Crystal sand is made from only one type of stone, hence the binding strength between the particles is good.',
        background: 'rgb(16 126 161)',
        number: '04'
      },
      right: {
        description:
          'It is made from different types of stones, so binding strength varies.'
      }
    },
    {
      left: {
        description:
          'Crystal sand has constant fineness modules of aggregate, so no necessity of the change in concrete mix design.',
        background: 'rgb(16 126 161)',
        number: '05'
      },
      right: {
        description:
          'As every truck of natural sand has different fineness modules, every time the mixture of concrete has to be changed.'
      }
    },
    {
      left: {
        description:
          'This sand is best as per Vaastu-shastra, as it doesn’t contain any pieces of Bones etc.',
        background: 'rgb(16 126 161)',
        number: '06'
      },
      right: {
        description:
          'Because of sand dragging, river beds have become deep, it’s harmful to nature.'
      }
    },
    {
      left: {
        description:
          'This sand is best as per Vaastu-shastra, as it doesn’t contain any pieces of Bones etc.',
        background: 'rgb(16 126 161)',
        number: '07'
      },
      right: {
        description:
          'This sand contains pieces of bones etc. So it is not suitable as per Vaastu-Shastra.'
      }
    },
    {
      left: {
        description:
          'Crystal sand doesn’t contain impurities like silt and silica etc. This sand doesn’t contain any organic material, so strength of the structure remains the same.',
        background: 'rgb(16 126 161)',
        number: '08'
      },
      right: {
        description:
          'This sand contains impurities like silt, silica etc. Natural sand contains organic impurities like pieces of wood, leaves, bones etc which after some time gets decayed and weakens the strength of the structure.'
      }
    },
    {
      left: {
        description:
          'This sand has proper gradation of coarse and fine aggregates, so voids are filled completely. This minimizes cement consumption.',
        background: 'rgb(16 126 161)',
        number: '09'
      },
      right: {
        description:
          'Natural sand which is available today doesn’t have fines below 600 microns in proper gradation. So, voids in the concrete are not filled properly and also increases cement consumption.'
      }
    }
  ]

  const [visibleRows, setVisibleRows] = useState(5)
  const [showParticles, setShowParticles] = useState(false)
  const isViewMore = visibleRows < data.length

  const toggleView = () => {
    const screenWidth = window.innerWidth
    let height
    if (screenWidth <= 320) {
      // Extra small screens (e.g., phones)
      height = isViewMore ? '1080vw' : '700vw'
    } else if (screenWidth <= 375) {
      // Extra small screens (e.g., phones)
      height = isViewMore ? '940vw' : '600vw'
    } else if (screenWidth <= 480) {
      // Extra small screens (e.g., phones)
      height = isViewMore ? '840vw' : '500vw'
    } else if (screenWidth <= 768) {
      // Small screens (e.g., tablets in portrait)
      height = isViewMore ? '280vw' : '180vw'
    } else if (screenWidth <= 1024) {
      // Medium screens (e.g., tablets in landscape)
      height = isViewMore ? '220vw' : '150vw'
    } else if (screenWidth <= 1440) {
      // Large screens (e.g., laptops)
      height = isViewMore ? '165vw' : '110vw'
    } else {
      // Extra-large screens (e.g., desktops, large monitors)
      height = isViewMore ? '125vw' : '90vw'
    }

    setVisibleRows(isViewMore ? data.length : 5)

    // Update the height dynamically
    const programSection = document.querySelector(
      '.crystal-sand .section.program'
    )
    const programSpacer = document.querySelector(
      '.crystal-sand .section-spacer.programs'
    )
    if (programSection) programSection.style.height = height
    if (programSpacer) programSpacer.style.height = height
  }

  const generateParticles = () => {
    const particles = []
    for (let i = 0; i < 50; i++) {
      particles.push(
        <div
          key={i}
          className='particle'
          style={{
            '--x': `${Math.random() * 200 - 100}px`,
            '--y': `${Math.random() * 200 - 100}px`,
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animation: `particleFloat ${1 + Math.random() * 2}s infinite`
          }}
        ></div>
      )
    }
    return particles
  }

  return (
    <div className='crystal-dif'>
      <div className='container'>
        <h1 className='heading-font'>Crystal Sand VS. Natural Sand</h1>
        {data.slice(0, visibleRows).map((row, index) => (
          <div className='row forSameFonts' key={index}>
            <div
              className='column left'
              style={{ backgroundColor: row.left.background }}
            >
              <p>{row.left.description}</p>
            </div>
            <div
              className='number-circle'
              style={{
                border: `2px solid ${row.left.background}`
              }}
            >
              {row.left.number}
            </div>
            <div className='column right'>
              <p>{row.right.description}</p>
            </div>
          </div>
        ))}
        <div
          className='toggle-button-wrapper'
          onMouseEnter={() => setShowParticles(true)}
          onMouseLeave={() => setShowParticles(false)}
        >
          <button className='toggle-button' onClick={toggleView}>
            {isViewMore ? 'View More' : 'View Less'}
          </button>
          {showParticles && (
            <div className='particles-field'>{generateParticles()}</div>
          )}
        </div>
      </div>
    </div>
  )
}
