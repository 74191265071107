import React, { useState } from 'react';
import '../css/RMC.css';

export default function RMCDifference () {
  const data = [
    {
      left: {
        description:
          'RMC requires fewer site workers, reducing labor costs.',
        background: 'rgb(16 126 161)',
        number: '01'
      },
      right: {
        description:
          '	Requires more labor for mixing the concrete materials.'
      }
    },
    {
      left: {
        description:
          'RMC requires no space storage for the raw materials.',
        background: 'rgb(16 126 161)',
        number: '02'
      },
      right: {
        description:
          'Requires storage space for raw materials like cement, sand, etc.'
      }
    },
    {
      left: {
        description:
          'Delivered directly to the site, reducing logistical challenges.',
        background: 'rgb(16 126 161)',
        number: '03'
      },
      right: {
        description: 'Raw materials need to be transported separately.'
      }
    },
    {
      left: {
        description:
          'RMC mix can be customized as per the project requirements.',
        background: 'rgb(16 126 161)',
        number: '04'
      },
      right: {
        description:
          'Customization is possible but with less precision.'
      }
    },
    {
      left: {
        description:
          'Curing starts immediately after pouring, ensuring better strength and durability.',
        background: 'rgb(16 126 161)',
        number: '05'
      },
      right: {
        description:
          'Curing is delayed due to onsite activities affecting the strength.'
      }
    },
    {
      left: {
        description:
          'RMC has uniform quality and consistency due to precise mix proportions and automated process.',
        background: 'rgb(16 126 161)',
        number: '06'
      },
      right: {
        description:
          'Quality and consistency is dependent on manual mixing and on-site conditions.'
      }
    },
    {
      left: {
        description:
          'RMC has a faster construction process as concrete is delivered in a ready-to-use state.',
        background: 'rgb(16 126 161)',
        number: '07'
      },
      right: {
        description:
          'Site mixed concrete is a time-consuming process as the mixing is done on-site.'
      }
    },
    {
      left: {
        description:
          'There will be minimal wastage as the materials are precisely measured and mixed.',
        background: 'rgb(16 126 161)',
        number: '08'
      },
      right: {
        description:
          'There will be higher wastage due to onsite handling and inaccurate mixing.'
      }
    },
    {
      left: {
        description:
          'Environmental impact is lower due to reduced wastage and optimized use of materials.',
        background: 'rgb(16 126 161)',
        number: '09'
      },
      right: {
        description:
          'Impact on the environment is higher due to dust generation on the construction site.'
      }
    }
  ]

  const [visibleRows, setVisibleRows] = useState(5)
  const [showParticles, setShowParticles] = useState(false)
  const isViewMore = visibleRows < data.length

  // const toggleView = () => {
  //   setVisibleRows(isViewMore ? data.length : 5)
  //   const height = isViewMore ? '125vw' : '90vw'
  //   document.querySelector('.Rmc .section.program').style.height =
  //     height
  //   document.querySelector(
  //     '.Rmc .section-spacer.programs'
  //   ).style.height = height
  // }


  const toggleView = () => {
    const screenWidth = window.innerWidth
    let height
    if (screenWidth <= 320) {
      // Extra small screens (e.g., phones)
      height = isViewMore ? '1080vw' : '700vw'
    } else if (screenWidth <= 375) {
      // Extra small screens (e.g., phones)
      height = isViewMore ? '940vw' : '600vw'
    } else if (screenWidth <= 480) {
      // Extra small screens (e.g., phones)
      height = isViewMore ? '840vw' : '500vw'
    } else if (screenWidth <= 768) {
      // Small screens (e.g., tablets in portrait)
      height = isViewMore ? '300vw' : '200vw'
    } else if (screenWidth <= 1024) {
      // Medium screens (e.g., tablets in landscape)
      height = isViewMore ? '220vw' : '150vw'
    } else if (screenWidth <= 1440) {
      // Large screens (e.g., laptops)
      height = isViewMore ? '165vw' : '110vw'
    } else {
      // Extra-large screens (e.g., desktops, large monitors)
      height = isViewMore ? '125vw' : '90vw'
    }

    setVisibleRows(isViewMore ? data.length : 5)

    // Update the height dynamically
    const programSection = document.querySelector(
      '.Rmc .section.program'
    )
    const programSpacer = document.querySelector(
      '.Rmc .section-spacer.programs'
    )
    if (programSection) programSection.style.height = height
    if (programSpacer) programSpacer.style.height = height
  }

  const generateParticles = () => {
    const particles = []
    for (let i = 0; i < 50; i++) {
      particles.push(
        <div
          key={i}
          className='particle'
          style={{
            '--x': `${Math.random() * 200 - 100}px`,
            '--y': `${Math.random() * 200 - 100}px`,
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animation: `particleFloat ${1 + Math.random() * 2}s infinite`
          }}
        ></div>
      )
    }
    return particles
  }
  return (
    <div className='RMCDif'>
      <div className='container'>
        <h1 className='heading-font'>Ready Mix Concrete  VS. Site Mixed Concrete</h1>
        {data.slice(0, visibleRows).map((row, index) => (
          <div className='row forSameFonts' key={index}>
            <div
              className='column left'
              style={{ backgroundColor: row.left.background }}
            >
              <p>{row.left.description}</p>
            </div>
            <div
              className='number-circle'
              style={{
                border: `2px solid ${row.left.background}`
              }}
            >
              {row.left.number}
            </div>
            <div className='column right'>
              <p>{row.right.description}</p>
            </div>
          </div>
        ))}
        <div
          className='toggle-button-wrapper'
          onMouseEnter={() => setShowParticles(true)}
          onMouseLeave={() => setShowParticles(false)}
        >
          <button className='toggle-button' onClick={toggleView}>
            {isViewMore ? 'View More' : 'View Less'}
          </button>
          {showParticles && (
            <div className='particles-field'>{generateParticles()}</div>
          )}
        </div>
      </div>
    </div>
  )
}
