import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap'
import AGSLogo from '../assets/Images/AGS-JB-Properties-Logo.jpg'
import AparnaLogo from '../assets/Images/Aparna-Logo.png'
import APRLogo from '../assets/Images/APR-Group-Logo.jpg'
import ARKLogo from '../assets/Images/ARK-Group-Logo.png'
import GLOBALLogo from '../assets/Images/global-mineral-works-Logo.png'
import GOWRAVENTURESLogo from '../assets/Images/gowra-logo-retina-Logo.png'
import JAINCONSTRUCTIONLogo from '../assets/Images/jain-constructions-logo.webp'
import NCLLogo from '../assets/Images/NCl-Logo.svg'
import NUVOCOLogo from '../assets/Images/Nuvoco-Logo.png'
import OMSREELogo from '../assets/Images/OmSree-Logo.png'
import RAJAPUSHPALogo from '../assets/Images/Rajapushpa.png'
import SHAPOORJILogo from '../assets/Images/shapoorji-Logo.svg'
import SREEMAN from '../assets/Images/sreemanlogo.png'
import SRIAS from '../assets/Images/Srias-Life-Logo.png'
import GRINFRACON from '../assets/Images/grill-logo.png'
import HellaInfra from '../assets/Images/inframarket-logo.png'
import Inventaa from '../assets/Images/Inventa-1-1-1.jpg'
import Kalapataru from '../assets/Images/kalpataru_power_transmission_ltd_logo.jpg'
import MYHOME from '../assets/Images/MyHome.png'
import '../css/Clients.css'
const clients = [
  {
    id: 1,
    name: 'SHAPOORJI PALLONJI AND COMPANY PRIVATE LIMITED',
    logo: SHAPOORJILogo
  },
  { id: 2, name: 'NUVOCO VISTAS CORPORATION LTD.', logo: NUVOCOLogo },
  { id: 3, name: 'NCL INDUSTRIES LIMITED', logo: NCLLogo },
  { id: 4, name: 'KALPATARU POWER TRANSMISSION LTD', logo: Kalapataru },
  { id: 5, name: 'HELLA INFRA MARKET PRIVATE LIMITED', logo: HellaInfra },
  
  { id: 6, name: 'APARNA CONSTRUCTIONS AND ESTATES PVT LTD', logo: AparnaLogo },
  { id: 7, name: 'MY HOME INFRASTRUCTURES PVT LTD.', logo: MYHOME },
  { id: 8, name: 'AGS & JB PROPERTIES', logo: AGSLogo },
  { id: 9, name: 'APR BUILDERS AND CONTRACTORS', logo: APRLogo },
  { id: 10, name: 'ARK BUILDERS', logo: ARKLogo },
  { id: 11, name: 'GLOBAL MINERAL WORKS', logo: GLOBALLogo },
  { id: 12, name: 'GOWRA VENTURES PRIVATE LIMITED', logo: GOWRAVENTURESLogo },
  { id: 13, name: 'JAIN CONSTRUCTIONS', logo: JAINCONSTRUCTIONLogo },
  { id: 14, name: 'OM SREE BUILDERS & DEVELOPERS', logo: OMSREELogo },
  { id: 15, name: 'SREEMAN CONSTRUCTIONS', logo: SREEMAN },
  { id: 16, name: 'RAJA PUSHPA PROPERTIES PRIVATE LIMITED', logo: RAJAPUSHPALogo },
  { id: 17, name: 'SRIAS LIFE SPACES LLP', logo: SRIAS },
  { id: 18, name: 'G R INFRACON PRIVATE LIMITED', logo: GRINFRACON },
  { id: 19, name: 'GOWRA ESTATES PRIVATE LIMITED', logo: GOWRAVENTURESLogo },
  { id: 20, name: 'GOWRA PALLADIUM PRIVATE LIMITED', logo: GOWRAVENTURESLogo },
  { id: 21, name: 'INVENTAA INDUSTRIES PRIVATE LIMITED', logo: Inventaa },
  { id: 22, name: 'RAJAPUSHPA INFRA PRIVATE LIMITED', logo: RAJAPUSHPALogo },

  { id: 23, name: 'JAIDHAR CONSTRUCTIONS', logo: 'https://via.placeholder.com/150' },
  { id: 24, name: 'K M R ESTATES & BUILDERS PRIVATE LIMITED', logo: 'https://via.placeholder.com/150' },
  { id: 25, name: 'NJR CONSTRUCTIONS PRIVATE LIMITED.', logo: 'https://via.placeholder.com/150' },
  { id: 26, name: 'QUBEX SILICON INDUSTRIES', logo: 'https://via.placeholder.com/150' },
  { id: 27, name: 'SRI LAKSHMI ENTERPRISES', logo: 'https://via.placeholder.com/150' },
  { id: 28, name: 'TELLAPUR TECHNOCITY PRIVATE LIMITED', logo: 'https://via.placeholder.com/150' },
  { id: 29, name: 'VEDANTA CONSTRUCTIONS', logo: 'https://via.placeholder.com/150' }
];


// Function to chunk clients into rows of 3
const chunkArray = (array, chunkSize) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

const Clients = () => {
  const [chunkSize, setChunkSize] = useState(9) // Default chunk size

  // Update chunk size based on screen width
  useEffect(() => {
    const updateChunkSize = () => {
      if (window.innerWidth < 768) {
        setChunkSize(1) // Mobile screens
      } else {
        setChunkSize(9) // Larger screens
      }
    }

    updateChunkSize() // Set initial chunk size
    window.addEventListener('resize', updateChunkSize) // Listen for resize events

    return () => window.removeEventListener('resize', updateChunkSize) // Cleanup on unmount
  }, [])

  const clientsWithLogos = clients.filter(client => client.logo)
  const clientChunks = chunkArray(clientsWithLogos, chunkSize)

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='client'>
      <section className='section-wrap clients'>
        <div className='section clients'>
          <div className='section-cont clients'>
            <Container fluid className='py-5'>
              <h2 className='text-[3.5rem] md:text-[4.5rem] lg:text-[5.5rem] lg:mt-[-72px] xl:mb-10 xl:text-[7.5rem] text-center mb-5'>
                Our Clients
              </h2>
              <Carousel interval={null}>
                {clientChunks.map((chunk, index) => (
                  <Carousel.Item key={index}>
                    <Row className='g-4 gy-4'>
                      {chunk.map(client => (
                        <Col key={client.id} xs={12} md={4} lg={4}>
                          <Card className='text-center shadow-sm border-0 h-100 d-flex flex-column'>
                            <div
                              className='d-flex align-items-center justify-content-center'
                              style={{
                                height: '150px',
                                overflow: 'hidden',
                                backgroundColor: 'rgb(16 126 161 / 69%)',
                                paddingTop: '60px'
                              }}
                            >
                              <Card.Img
                                variant='top'
                                src={client.logo}
                                style={{
                                  objectFit: 'contain',
                                  maxHeight: '100%',
                                  maxWidth: '80%',
                                  marginBottom: '60px'
                                }}
                              />
                            </div>
                            <Card.Body className='d-flex align-items-center justify-content-center'>
                              <Card.Title className='text-muted text-center forSameFonts'>
                                {client.name}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Container>
            <div className='section-anim-trigger'></div>
          </div>
        </div>
      </section>
      <section className='section-spacer clients'></section>
    </div>
  )
}

export default Clients
