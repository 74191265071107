import React, { useEffect } from 'react'
import RMCBannerVideo from '../assets/Videos/READY MIX CONCRETE 5.71 MB).mp4'
import '../css/RMC.css'
import '../css/MediaQueries/MediaQueryRMC.css'
import { Container } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import RmCvIdEo from '../../src/assets/Videos/rmcnew.mp4'
import RMCDifference from './RMCDifference'
export default function RMCPage () {
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='Rmc'>
      <Helmet>
        <title>
          Ready Mix Concrete manufacturer and Supplier | Endula Infra
        </title>
        <meta
          name='keywords'
          content='ready mix supplier,ready mix concrete manufacturer,ready mix concrete suppliers,rmc readymix concrete,rmc suppliers.'
        />
        <meta
          name='description'
          content='Endula complements your construction vision by manufacturing and supplying high quality, customized, reliable and suatainable ready mix concrete in all grades.'
        />
      </Helmet>
      <div className='fac-hero'>
        <div className='heading-wrap fac'>
          <h1
            className='hero-heading heading-font'
            style={{ opacity: 1 }}
            words-slide-up=''
            text-split=''
          >
            <span
              className='word'
              style={{
                display: 'inline-block',
                transform: 'translate(0px, 0px)',
                opacity: 1
              }}
            >
              <span className='char' style={{ display: 'inline-block' }}>
                R
              </span>
              <span className='char'>e</span>
              <span className='char'>a</span>
              <span className='char'>d</span>
              <span className='char'>y</span>
              <span>&nbsp;</span>
              <span className='char'>M</span>
              <span className='char'>i</span>
              <span className='char'>x</span>
              <span>&nbsp;</span>
              <span className='char'>C</span>
              <span className='char'>o</span>
              <span className='char'>n</span>
              <span className='char'>c</span>
              <span className='char'>r</span>
              <span className='char'>e</span>
              <span className='char'>t</span>
              <span className='char'>e</span>
            </span>
          </h1>
        </div>
        <div
          className='arrows-trigger fac'
          style={{
            transform:
              'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'
          }}
        ></div>
        <div className='background-noise-new'></div>
        <div className='hero-gradient'></div>
        <div className='video-fac w-background-video w-background-video-atom'>
          <video autoPlay loop muted playsInline>
            <source src={RMCBannerVideo} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='section-anim-trigger'></div>
      </div>
      <section className='section-spacer fac-video'></section>
      <div className='section-wrap campus'>
        <section className='section campus'>
          <div className='section-cont campus'>
            <div className='campus-heading heading-font '>
              Endula Infra LLP RMC is the best in the city
            </div>
            <div className='campus-paragraph forSameFonts'>
              RMC is a concrete manufactured in a batching plant for supply to
              sites in freshly mixed or unhardened state, to be mixed with water
              and compacted to form concrete. It is used extensively in
              construction industry since it has many advantages over on-site
              method of concrete production.
            </div>
            <div className='RMC-img-wrap'>
              <div className='concrete-image'></div>
            </div>
          </div>
        </section>
      </div>
      <section className='section-spacer campus'></section>
      <div className='section-wrap email'>
        <section className='section email about-section'>
          <div className='section-cont email d-flex justify-center align-items-center'>
            <div className='email-lottie text-center'>
              <video autoPlay loop muted playsInline>
                <source src={RmCvIdEo} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='rmc-content'>
              <p className='forSameFonts'>
                We provide a wide range of mix-designs ranging from M10 to M100
                which is a feat that cannot be replicated by anyone in the city.
              </p>
              <p className='forSameFonts'>
                Our Ready Mix Concrete ranges from M10 to M100 each suitable for
                various construction needs
              </p>
              <ul className='forSameFonts'>
                <li>
                  M10 : Ideal for simple constructions like pathways and
                  non-load bearing walls.
                </li>
                <li>
                  M15 – M20: Ideal for Residential and commercial building
                  Foundations
                </li>
                <li>
                  M25 – M35: Ideal for Concrete structures such as slabs, beams
                  and columns.
                </li>
                <li>
                  M40 – M50: Ideal for Heavy duty infrastructure projects like
                  highways and bridges.
                </li>
                <li>
                  M100: Ideal for high strength pre stressed beams, High-rise
                  buildings, Marine and Offshore structures.
                </li>
              </ul>
            </div>
          </div>
          <div className='section-anim-trigger'></div>
        </section>
      </div>
      <div className='section-spacer email'></div>
      <div className='section-wrap programs '>
        <section className='section program'>
          <div className='section-cont program '>
             <div className='benefits-heading'>
                <h2>Benefits of Ready Mix Concrete</h2>
              </div>
              <RMCDifference />
            <div className='parallax-anim-trigger'></div>
          </div>
        </section>
      </div>
      <section className='section-spacer programs'></section>
      <div className='section-wrap people'>
        <section className='section people'>
          <div className='section-cont people'>
            <div
              className='people-heading people text-center heading-font '
              style={{
                transform:
                  'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                opacity: 1
              }}
            >
              We boast a state of the art lab to check quality control with no
              margin of error
            </div>
            <div
              className='people-section-descr science people forSameFonts'
              style={{
                transform:
                  'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                opacity: 1
              }}
            >
              Our safety measures for workers and equipment material is beyond compare using world class PSSR certified (pre-start Safety Review) where we went through rigorous safety checks before starting the plant.
            </div>
          </div>
        </section>
      </div>
      <section className='section-spacer people'></section>
    </div>
  )
}
