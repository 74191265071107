import React from 'react';
import './App.css';
import Home from './components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; // Import Routes instead of Router
import Header from './components/Header'
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
import Clients from './components/Clients';
import AboutUs from './components/AboutUs';
import QualityAndProcess from './components/QualityAndProcess';
import CrystalSandPage from './components/CrystalSandPage';
import RMCPage from './components/RMCPage';
import AgrregatesPage from './components/AgrregatesPage';
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/Quality-and-Process" element={<QualityAndProcess/>} />
        <Route path="/Crystal-Sand" element={<CrystalSandPage/>} />
        <Route path="/Ready-Mix-Concrete" element={<RMCPage/>} />
        <Route path="/Aggregates" element={<AgrregatesPage />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        {/* <Route path="/Clients" element={<Clients />} /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;


